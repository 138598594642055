import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useCodeList } from '../../../../../global/helpers/hooks';
import { getFilters, getCompanyId, getPerson } from '../../../../../global/helpers/actions';

import {
  XsDatePicker,
  XsAutocompleteServer,
  XsTextField,
  XsCodelistSearchSelect,
  XsNumberMaskField
} from '../../../../../global/ui';
import { ISubmitter } from '../../../../../global/interfaces/common';
import {
  ICodeListObject,
  ICodeListResponse
} from '../../../../../global/interfaces/responses/codeList.response.interface';
import EnergeticAuditStore from '../../EnergeticAuditStore';
import API from '../../../../../global/config/api';

type THeaderForm = {
  energeticAuditId: string;
  newEA: boolean;
};

function EnergeticAuditHeaderForm({ energeticAuditId }: THeaderForm) {
  const { t } = useTranslation();

  const { control, setValue } = useFormContext();

  const { data: sectors } = useCodeList<ICodeListResponse>('Entity.CompanySector');
  const { data: skNace } = useCodeList<ICodeListResponse>('Entity.SK.NACE');

  const isSent = () => {
    return EnergeticAuditStore.eaStatus !== 'pre';
  };

  return (
    <>
      <Grid item container xs={9} pr={8} wrap='nowrap'>
        <Grid item container xs={6} pr={4} spacing={3} alignContent='baseline'>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name='preparationDate'
                control={control}
                // defaultValue=''
                render={({ field: { ref, ...field } }) => (
                  <XsDatePicker
                    label={t('global.date.preparation')}
                    {...field}
                    required
                    disabled={isSent()}
                    maxDate={new Date()}
                    minDate={new Date(2000, 0, 1)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='submitionReason'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsCodelistSearchSelect
                    required
                    menuWidth='175%'
                    disabled={isSent()}
                    codelistKey={'EnergeticAudit.SubmissionReason'}
                    label={t('energeticAudit.audit_header.reason')}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} alignItems='center'>
            <Grid item xs>
              <Typography variant='h6'>{t('energeticAudit.audit_header.submitter_title')}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2} style={{ display: 'inherit' }}>
            <Grid item xs={12}>
              <Controller
                name='submitter'
                render={({ field: { ref, ...rest } }) => (
                  <XsAutocompleteServer
                    {...rest}
                    disabled={isSent()}
                    label={t('energeticAudit.audit_header.name_ico_submitter')}
                    request={{
                      uri: API.EACompanySubject(),
                      payload: {
                        load: (value) =>
                          getFilters(
                            getCompanyId()
                              ? [`company_id=${getCompanyId()}`, `_id=${value}`]
                              : [`person_id=${getPerson()?._id}`, `_id=${value}`]
                          ),
                        search: (value) =>
                          getFilters(
                            getCompanyId()
                              ? [`company_id=${getCompanyId()}`, `search=${value}`]
                              : [`person_id=${getPerson()?._id}`, `search=${value}`]
                          )
                      }
                    }}
                    identFn={(option: ISubmitter) => option._id}
                    labelFn={(option: ISubmitter) => `${option.name} ${option.identifier}`}
                    onApply={(option: ISubmitter, isLoad: boolean) => {
                      if (!isLoad || (isLoad && !energeticAuditId)) {
                        setValue('houseNumber', option?.house_number ?? '', { shouldValidate: true });
                        setValue('street', option?.street_plain ?? '', { shouldValidate: true });
                        setValue('zip', option?.zip_plain ?? '', { shouldValidate: true });
                        setValue('submitterName', option?.name ?? '');
                        setValue('submitterIco', option?.identifier ?? '');
                        setValue('submitterCountryId', option?.country_id ?? '');

                        if (option && option.sector_id && sectors) {
                          const sector = sectors.rows.find((x: ICodeListObject) => x._id === option.sector_id);
                          setValue('sector', sector ?? '', { shouldValidate: true });
                        } else {
                          setValue('sector', '', { shouldValidate: true });
                        }

                        if (option && option.classification_id && skNace) {
                          const nace = skNace.rows.find((x: ICodeListObject) => x._id === option.classification_id);
                          setValue('skNace', nace ?? '', { shouldValidate: true });
                        } else {
                          setValue('skNace', '', { shouldValidate: true });
                        }

                        setValue('city', option?.city_id ?? '', { shouldValidate: true });
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={7}>
              <Controller
                name='street'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsTextField type='text' label={t('global.street')} {...field} disabled required />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name='houseNumber'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsTextField type='text' label={t('global.house_number')} {...field} disabled required />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={7}>
              <Controller
                name='city'
                render={({ field: { ref, ...rest } }) => (
                  <XsAutocompleteServer
                    {...rest}
                    disabled
                    required
                    label={t('global.village')}
                    request={{
                      uri: '/isee/cl/city',
                      payload: {
                        load: (value) => getFilters([`code=${value}`]),
                        search: (value) => getFilters([`search_column=${value}`])
                      }
                    }}
                    identFn={(option) => option._id}
                    labelFn={(option) => `${option.name_ext} (${option.county_name})`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name='zip'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsNumberMaskField
                    {...field}
                    label={t('global.psc')}
                    format='### ##'
                    align='left'
                    disabled
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={7}>
              <Controller
                name='sector'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsCodelistSearchSelect
                    required
                    disabled={isSent()}
                    menuWidth='150%'
                    codelistKey={'Entity.CompanySector'}
                    label={t('energeticAudit.audit_header.sector')}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name='skNace'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsCodelistSearchSelect
                    required
                    disabled={isSent()}
                    menuWidth='200%'
                    codelistKey={'Entity.SK.NACE'}
                    getOptionLabel={(option) => `${option.code_ext} - ${option.name_ext}`}
                    label={t('energeticAudit.audit_header.sk_nace')}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={6} pl={4} spacing={3} alignContent='baseline'>
          <Grid item xs={12}>
            <Controller
              name='auditName'
              control={control}
              render={({ field: { ref, ...field } }) => (
                <XsTextField
                  type='text'
                  label={t('energeticAudit.audit_header.audit_name')}
                  {...field}
                  disabled={isSent()}
                  counter={100}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>{t('energeticAudit.audit_header.auditor_title')}</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name='auditorFirstName'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsTextField type='text' label={t('global.first_name')} {...field} required disabled={isSent()} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='auditorLastName'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsTextField type='text' label={t('global.last_name')} {...field} required disabled={isSent()} />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name='auditorCertificate'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsTextField
                    type='text'
                    label={t('energeticAudit.audit_header.certificate')}
                    {...field}
                    disabled={isSent()}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name='auditorEmail'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsTextField type='text' label={t('global.email1')} {...field} disabled={isSent()} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name='auditorPhone'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsTextField type='text' label={t('global.phone_number')} {...field} disabled={isSent()} />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name='auditorEmail2'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <XsTextField type='text' label={t('global.email2')} {...field} disabled={isSent()} />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default EnergeticAuditHeaderForm;
